








































// @vuese
// @group Views
// Reset Password

import PasswordValidationFields from "@/components/forms/PasswordValidator.vue";
import { Login as LoginRoute } from "../router";
import { Store } from "@/store/store";
import Component from "vue-class-component";

interface Errors {
  name: Array<string>;
  password: Array<string>;
}

interface Warnings {
  password: Array<string>;
}

interface Valid {
  valid: boolean, 
  password?: string
}

/**
 * Allows a user to reset their password
 */
@Component({
  components: {
    PasswordValidationFields
  },
  metaInfo: {
    title: "Reset Your Password"
  }
})
export default class ResetPassword extends Store {
  componentTitle = "Reset Your Password";

  LoginRoute = LoginRoute;
  match = false;
  strengthValue = 0;
  password = "";
  codeIsValid = false;
  thinkingAboutIt = false;
  passwordConfirmation = "";

  errors: Errors = {
    name: [],
    password: []
  };
  warnings: Warnings = {
    password: []
  };

  async mounted() {
    this.thinkingAboutIt = true;
    await this.validateForgotPasswordCode(this.$route.params.code)
      .then(() => {
        this.codeIsValid = true;
      })
      .catch(err => {
        
      });
    this.thinkingAboutIt = false;
  }
  get strength() {
    let output;
    switch (this.strengthValue) {
      case 0:
        output = "BAD";
        break;
      case 1:
        output = "WEAK";
        break;
      case 2:
        output = "OK";
        break;
      case 3:
        output = "GOOD";
        break;
      case 4:
        output = "GREAT";
        break;
    }
    return output;
  }

  /**
   * Gets invoked when the passwords match
   * @arg {Boolean} match
   * @arg {String} pass
   * @vuese
   * @returns {void}
   */
  passwordMatch(value:Valid) {
    
    this.match = value.valid;
    const matchError = "Passwords Do Not Match";
    if (!value.valid) {
      if (!this.errors.password.includes(matchError)) {
        this.errors.password.push(matchError);
      }
    } else {
      this.errors.password.splice(
        this.errors.password.indexOf(matchError, 1),
        1
      );
    }

    this.password = value.password || "";
    this.passwordConfirmation = this.password; // Bit naughty but it's valid
  }
  /**
   * Returns the strength of the password
   * @arg {Boolean} match
   * @arg {String} pass
   * @vuese
   * @returns {void}
   */
  passwordStrength(score: number) {
    const matchError = "Password Is Not Good Enough";
    if (score < 2) {
      if (!this.warnings.password.includes(matchError)) {
        this.warnings.password.push(matchError);
      }
    } else {
      this.warnings.password.splice(
        this.warnings.password.indexOf(matchError, 1),
        1
      );
    }
  }
  /**
   * Submit the new password to the server
   * @arg {String} password
   * @vuese
   * @returns {void}
   */
  async submit(password: string) {
    this.resetPassword({
      token: this.$route.params.code,
      password,
      password_confirmation: password
    })
      .then(() => {
        this.$toast.success("Password Has Been Reset Capn'n");
        this.$router.push(LoginRoute);
      })
      .catch(err => {
        
        this.$toast.error("Failed to reset password");
      });
  }
}
